<template>
  <h1>{{ $gettext('News dal mondo cocktail') }}</h1>
  <div class="  news-wrapper">
    <div class="news">
      <news-card
        v-for="item in listing"
        :key="item.id"
        :title="item.title"
        :header="item.imageUrl"
        :id="item.id"
      ></news-card>
    </div>
  </div>
</template>

<script>
import NewsCard from '@/components/shared/NewsCard';

export default {
  name: 'news-listing',
  components: { NewsCard },

  data() {
    return {
      listing: [],
      imageUrl: '',
    };
  },

  methods: {
    domDecoder(str) {
      let parser = new DOMParser();
      let dom = parser.parseFromString(
        '<!doctype html><body>' + str,
        'text/html',
      );
      return dom.body.textContent;
    },

    async loadNews() {
      var resp = await this.axios.get(this.api.newsListing + "?"  + this.$store.getters['auth/getLanguageRest']);
      resp.data.forEach((item, index)  => {
        //console.log(index);
        this.axios.get(this.api.getMediaUrl + item.featured_media)
          .then((res) => {
            //alert("-"+res.data.source_url+"-");
            var imageUrl = res.data.source_url;
            this.listing.push({
              id: item.id,
              title: this.domDecoder(item.title.rendered),
              imageUrl: imageUrl
            });
          });
      });
      return ;

      resp.then((res) => {
          const listing = [];

          for (const id in res.data) {
            var tmp = this.axios.get(this.api.getMediaUrl + res.data[id].featured_media);

            listing.push({
              id: res.data[id].id,
              title: this.domDecoder(res.data[id].title.rendered),
              imageUrl: this.axios
                .get(this.api.getMediaUrl + res.data[id].featured_media)
                .then((res) => {
                  this.imageUrl = res.data.source_url;
                })
                .catch((err) => console.log(err)),
            });
          }

          this.listing = listing;
        })
        .catch((err) => {
          console.log('Errror: ' + err);
        });
    },
  },

  mounted() {
    this.loadNews();
  },
};
</script>

<style scoped>
h1 {
  margin: 25px 0;
  font-size: 1.5rem;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 600;
  color: var(--var-primary-color);
}

.news {
  width: 90%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  margin: 0 auto 30px;
}
</style>

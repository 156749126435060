<template>
  <div class="news-item">
    <img class="news__image" :src="header" />
    <div class="news__info">
      <div class="news__title">{{ title }}</div>
      <div class="news__read-more">
        <router-link :to="{ name: 'News Details', params: { id: id } }">
          {{ $gettext('Leggi tutto') }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import '@/api/index.js';
export default {
  name: 'NewsCard',
  props: ['title', 'header', 'id'],

  methods: {
    getImage() {
      return this.axios.get(this.api.getMediaUrl + this.id).then((res) => {});
    },
  },
};
</script>

<style scoped>
.news-item {
  margin-bottom: 30px;
  background-color: #fff;
}

.news__info {
  padding: 20px;
}

.news__image {
  width: 100%;
}

.news__title {
  margin-top: 0;
  font-size: 1rem;
  color: #333;
  display: block;
  text-align: left;
  font-weight: 600;
}

.news__read-more {
  display: block;
  margin-top: 10px;
  font-size: 1rem;
  font-weight: 700;
  text-align: left;
}

.news__read-more a {
  padding: 5px 0;
  color: var(--var-primary-color);
  text-decoration: none;
  text-transform: uppercase;
  text-align: left;
}
</style>
